<template>
	<div class="full-height flex-column">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div
				v-if="is_manager"
				class="save"
			>
				<button
					class="btn_save"
					@click="toAdd"
				><img :src="require('@/assets/image/icon_edit.svg')"></button>
			</div>
		</div>

		<div class="mt-50 pb-150">
			<div class="under-line">
				<ul>
					<template
						v-for="(vote, index) in items_voting"
					>
						<li
							v-if="vote.board_number == $route.params.v_id"
							:key="'vote_' + index"
							class="pl-20 pt-5 pb-5  size-px-16 justify-space-between items-center"
							@click="is_on_half = true"
						>{{ vote.board_name }} <button><img :src="require('@/assets/image/icon-arrow-down.svg')" class="width-80" /></button></li>
					</template>
				</ul>
			</div>
			<div class="mt-20 justify-space-around gap-10 prl-20">
				<template
					v-for="(t_type, t_index) in type_list"
				>
					<button
						v-if="t_type.permission"
						:key="'type_' + t_index"
						@click="toList(t_type.code)"
						class="btn-inline size-px-13 box radius-20 ptb-10 flex-1"
						:class="type == t_type.code ? 'bg-red color-white' : 'bg-gray-light'"
					>{{ t_type.name }}</button>
				</template>
			</div>
			<div class="pa-20 size-px-14">
				<ul
					v-if="items.length > 0"
				>
					<li
						v-for="(item, index) in list_items"
						:key="'item_' + index"
						class="box radius-20 color-white position-relative mb-10 position-relative"
						style="height: 200px"
					>

						<div
							class="full-height"
						>
							<div
								v-for="(image, i_index) in item.vote_thumbnail_img.vote_thumbnail_img_partial_list"
								:key="'image_' + i_index"
								:class="{ 'full-height' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
							>
								<img :src="image.vote_thumbnail_img_url" :class="{ 'object-cover' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }" class="width-100"/>
							</div>
						</div>
						<div
							class="position-absolute-full flex-column justify-space-between "
							@click="toDetail(item)"
						>
							<div class="pa-20 full-height text-center flex-column justify-center " style="  background-color: rgba( 0, 0, 0, 0.3 );">
								<div class="size-px-16 font-weight-600">{{ item.vote_title | maxLength(30, '...')}}</div>


								<hr class="top-line width-40 ma-auto mt-10 mb-10" />

								<div class="size-px-13">{{ item.sDate}} ~ {{ item.eDate }}</div>

							</div>
							<div
								class="pa-20 mt-auto color-red font-weight-600 justify-space-between" style="  background-color: rgba( 0, 0, 0, 0.7 );"
							>
								<div>{{ item.cartl_vote_state_code_name }}</div>
								<div class="color-gray"><img :src="require('@/assets/image/icon_voting_count.svg')" /> {{ item.vote_participation_count | makeComma }}</div>
							</div>
						</div>
						<button
							v-if="is_manager && (item.cartl_vote_state_code == 'CA03700006' || item.cartl_vote_state_code == 'CA03700003')"
							class="position-absolute"
							style="top: 15px; right: 15px;"
							@click="postHidden(item)"
						>
							<img
								v-if="item.cartl_vote_state_code == 'CA03700003'"
								:src="require('@/assets/image/icon_eye_hidden.svg')" />
							<img
								v-else
								:src="require('@/assets/image/icon_eye.svg')" />
						</button>
						<button
							v-if="is_manager && (item.cartl_vote_state_code == 'CA03700001' || item.cartl_vote_state_code == 'CA03700005')"
							class="position-absolute "
							style="top: 15px; right: 15px;"
							@click="onDelete(item)"
						><v-icon class="color-red">mdi-delete-forever</v-icon>
						</button>
					</li>
				</ul>
				<Empty
					v-else
					class="mt-80"
				></Empty>
			</div>
		</div>


		<Popup_confirm
			v-if="is_on_delete"

			@click="postDelete"
			@cancel="is_on_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.notice.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{ $language.notice.article_delete_message }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.notice.article_delete_message_confirm }}</template>
		</Popup_confirm>

		<PopupHalf
			v-if="is_on_half"
			:user="user"
			:title="'투표 '"

			@cancel="is_on_half = false"
		>
			<template
				v-slot:half_items
			>
				<li
					v-for="(vote, index) in items_voting"
					:key="'vote_' + index"

					class="mb-10"

					@click="toVote(vote)"
				>{{ vote.board_name }}</li>
			</template>

		</PopupHalf>
	</div>
</template>

<script>
	import Empty from "@/view/Layout/Empty";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import PopupHalf from "@/view/Layout/PopupHalf";
	export default {
		name: 'CartelVotingList'
		, props: ['user']
		, components: {PopupHalf, Popup_confirm, Empty}
		, data: function(){
			return {
				program: {
					title: this.$language.voting.title_voting_board
					, name: this.$language.voting.title_voting_board
					, type: 'cartel_sub'
					, not_header: true
				}
				, type: this.$route.params.type ? this.$route.params.type : 'ing'
				, item_voting: {}
				, items: []
				, item_search: {
					page: 1
					, list_cnt: 10
				}
				, is_on_delete: false
				, item_delete: {}
				, items_voting: []
				, is_on_half: false
			}
		}
		, computed: {
			is_manager: function(){
				let t = false
				if(this.item_voting.requester_cartl_member_grade_code == 'CA02500001' || (this.item_voting.requester_cartl_member_grade_code == 'CA02500002' && this.item_voting.admin_authority_settup_fg == 'Y')){
					t = true
				}

				return t
			}
			, type_list: function() {
				return [
					{permission: true, name: '진행중', code: 'ing'}
					, {permission: this.is_manager, name: '예약', code: 'reserve'}
					, {permission: true, name: '종료', code: 'end'}
					, {permission: this.is_manager, name: '임시저장', code: 'temp'}
				]
			}
			, list_items: function(){
				return this.items.filter( (item) => {
					item.bg_img = item.vote_original_img.vote_original_img_url

					item.sDate = item.vote_startdt.slice(0, 16)
					item.eDate = item.vote_enddate.slice(0, 16)
					/*
					let sDate = item.vote_startdt?.split('T')
					item.sDate = sDate[0] + ' ' + sDate[1]?.slice(0, 5)
					let eDate = item.vote_enddate?.split('T')
					item.eDate = eDate[0] + ' ' + eDate[1]?.slice(0, 5)
					*
					 */
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					let url = this.$api_url.api_path['get_voting_bbs_list_' + this.type]
					if(!this.is_manager){
						url = this.$api_url.api_path['get_open_voting_list_' + this.type]
						if(this.user.member_number){
							url = this.$api_url.api_path['get_voting_list_' + this.type]
						}
					}


					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, type: this.type
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
						}
						, type: true
					})
					if(result.success){
						this.items = result.data.cartl_vote_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVotingInfo: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_voting_board
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_board
					}

					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
						}
						, type: true
					})
					if(result.success){
						this.item_voting = result.data
						this.program.title = result.data.cartl_vote_board_name
						switch (this.item_voting.requester_cartl_member_grade_code){
							case 'CA02500001':
								break
							case 'CA02500002':
								break
							default: case 'CA02500003':
								if(this.item_voting.vote_release_settup_fg != 'Y'){
									throw this.$language.auth.no_authority_lookup
								}
								break
						}
						await this.getVotingList()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.goBack()
				}finally {
					this.$bus.$emit('on', false)
					// await this.getData()
				}
			}
			, toList: function(type){
				this.type = type
				this.$bus.$emit('to', { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, type: type, auth: this.$route.params.auth}})
				this.getData()
			}
			, toAdd: function(){
				this.$bus.$emit('to', { name: 'CartelVotingEdit', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id}})
			}
			, toDetail: function(item){
				if(this.user.member_number){
					if(item.cartl_vote_state_code == 'CA03700002' || item.cartl_vote_state_code == 'CA03700003' || item.cartl_vote_state_code == 'CA03700006'){
						this.$bus.$emit('to', { name: 'CartelVotingInfo', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, e_id: item.cartl_vote_number, from: this.$route.fullPath }})
					}else{
						this.$bus.$emit('to', { name: 'CartelVotingEdit', from: this.$route.fullPath, params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, e_id: item.cartl_vote_number, from: this.$route.fullPath }})
					}
				}else{
					this.$bus.$emit('onLogin')
				}
			}
			, goBack: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: this.$route.params.idx}})
			}
			, postHidden: async function(item){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_voting_hidden
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: item.cartl_vote_number
							, cartl_vote_state_code: item.cartl_vote_state_code == 'CA03700003' ? 'CA03700006' : 'CA03700003'
						}
						, type: true
					})
					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onDelete: function(item){
				this.item_delete =  item
				this.is_on_delete = true
			}
			, postDelete: async function(){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.delete_voting
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.item_delete.cartl_vote_number
						}
						, type: true
					})
					if(result.success){
						await this.getData()
						this.is_on_delete = false
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVotingList: async function(){
				try {
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_vote_list
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_board_list
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, vote_release_settup_fg: 'Y'
						}
						, type: true
					})
					if (result.success) {
						this.items_voting = result.data.cartl_vote_board_list
						await this.getData()
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.goBack()
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, toVote: function (vote){
				this.$bus.$emit('to', {name: 'CartelVotingList', type: 'href', params: { idx: this.$route.params.idx, v_id: vote.board_number, type: this.$route.params.type, from: this.$route.name }})
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getVotingInfo()

		}

	}
</script>